<template>
  <!-- 选择房间 -->
  <div class="nav d-flex zt">
    <!-- 初级房间 -->
    <div class="primary" @click="getbigkill(1)"></div>
    <!-- 中级房间 -->
    <div class="intermediate" @click="getbigkill(2)"></div>
    <!-- 高级房间 -->
    <div class="senior" @click="getbigkill(3)"></div>
    <!-- 敬请期待 -->
    <div class="expectation" @click="getbigkill(4)">
      <!-- <img src="@/assets/images/bigkill/expectation.png" alt /> -->
      <div class="text color-white">大逃杀S1赛季剩余2天</div>
    </div>
  </div>
</template>

<script>
import { getbigkillmodel } from "../../../api/index";
export default {
  data() {
    return {
      roomList: []
    };
  },

  methods: {
    getbigkill(type) {
      if (type === 1) {
        getbigkillmodel(type).then(res => {
          if (res.data.code == 200) {
            // console.log(res);
            this.$router.push(`/bigKillgethome/${type}`);
            this.$bus.$emit("roominfo", res.data.roomNumGrouping);
          }
        });
      } else if (type === 2) {
        getbigkillmodel(type).then(res => {
          if (res.data.code == 200)
            this.$router.push(`/bigKillgethome/${type}`);
          this.$bus.$emit("roominfo", res.data.roomNumGrouping);
        });
      } else if (type === 3) {
        getbigkillmodel(type).then(res => {
          if (res.data.code == 200)
            this.$router.push(`/bigKillgethome/${type}`);
          this.$bus.$emit("roominfo", res.data.roomNumGrouping);
        });
      } else if (type === 4) {
        this.$message("敬请期待！");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.nav {
  width: 1340px;
  height: 460px;
  margin: 35px auto 10px;
  div {
    width: 330px;
    height: 374px;
    flex: none;
    margin-top: 30px;
  }
  .primary {
    background: url("~@/assets/images/bigkill/primary.png") no-repeat;
    background-size: 100% 100%;
  }
  .intermediate {
    background: url("~@/assets/images/bigkill/intermediate.png") no-repeat;
    background-size: 100% 100%;
  }
  .senior {
    background: url("~@/assets/images/bigkill/senior.png") no-repeat;
    background-size: 100% 100%;
  }
  .expectation {
    text-align: center;
    background: url("~@/assets/images/bigkill/allbg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    // img {
    //   width: 140px;
    //   height: 140px;
    //   margin-top: 110px;
    // }
    .text {
      font-weight: none;
      font-size: 35px;
      left: 80px;
      width: 60%;
      margin: 110px auto;
      font-style: italic;
      animation: rotate 0.3s ease infinite;
      /* 注意，要开启绝对定位哦 */
      position: absolute;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0);
      }

      20% {
        transform: rotate(-2deg);
      }

      60% {
        transform: rotate(0);
      }

      80% {
        transform: rotate(2deg);
      }

      100% {
        transform: rotate(0);
      }
    }
  }
}
@media (max-width: 550px) {
  .nav {
    width: 100%;
    height: 360px;
    margin: 15px auto 10px;
    justify-content: space-around;
    flex-wrap: wrap;
    div {
      width: 169px;
      height: 248px;
      flex: none;
      margin-top: 10px;
    }
    .primary {
      background: url("~@/assets/images/bigkill/primary-m.png") no-repeat;
      background-size: 100% 100%;
    }
    .intermediate {
      background: url("~@/assets/images/bigkill/intermediate-m.png") no-repeat;
      background-size: 100% 100%;
    }
    .senior {
      background: url("~@/assets/images/bigkill/senior-m.png") no-repeat;
      background-size: 100% 100%;
    }
    .expectation {
      text-align: center;
      background: url("~@/assets/images/bigkill/allbg-m.png") no-repeat;
      background-size: 100% 100%;
      position: relative;

      .text {
        display: none;
      }
    }
  }
}
</style>
